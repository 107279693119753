import React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import { subDays, format, addDays } from 'date-fns';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Cards from '../../component/card_list/card_list';
import DatePickerOne from '../../component/date_picker_one/date_picker_one';

import CustomMixBarchart from '../../component/custom_mix_barchart/custom_mix_barchart';
import LoadingSpinner from '../../component/loading_spinner/loading_spinner';
import Radiogroup from '../../component/radiogroup/radiogroup';

import { useAuth } from '../../Context/Auth';
import { useMenuInfo } from '../../Context/MenuInfo';

import { getFormatedDay, getSubDay, toCurrency } from '../../common/format.js';
import { setSessionData, getSessionData } from '../../common/session.js';

import styles from '../common/page_common.module.css';

const colors = {
  grey: '#454545',
  violet: '#8884d8',
};

const radioGroupRef = [
  { label: '매출기준', value: 'amount' },
  { label: '건수기준', value: 'count' },
];

const useStyles = makeStyles((theme) => ({
  searchBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  emptyBox: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  datepickerWrapper: {
    display: 'flex',
    maxWidth: 'fit-content',
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
    height: '4rem',
    [theme.breakpoints.down('xs')]: {
      height: '3rem',
      marginRight: 0,
    },
  },

  datepicker: {
    borderBottom: '2px solid transparent',

    '&:hover': {
      borderBottom: '2px solid rgb(204,204,204)',
    },
  },

  dayBtn: {
    height: '2rem',
    border: `1px solid ${colors.violet}`,

    [theme.breakpoints.down('xs')]: {
      height: '1.6rem',
      minWidth: '0.7rem',
      fontSize: '0.6rem',
    },
  },

  arrowBtn: {
    minWidth: 30,
    width: 45,
    height: 45,
    borderRadius: '50%',
    padding: 4,
    color: '#888',
    fontSize: '100%',

    '& span': {
      width: 45,
      height: 45,
    },
  },

  arrowIcon: {
    width: 40,
    height: 40,
    padding: 0,
  },

  cardWrapper: {
    width: '100%',
    marginBottom: '1rem',

    [theme.breakpoints.down('xs')]: {
      marginBottom: '0.7rem',
    },
  },

  cards: {
    display: 'flex',
    fontSize: '1rem',
    gap: 10,

    [theme.breakpoints.down('sm')]: {
      //height: 250,
      flex: '1 1',
      flexWrap: 'wrap',
      fontSize: '0.9rem',
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: '0.2rem',
      fontSize: '0.8rem',
    },
  },

  chart: {
    width: '100%',
    height: 450,
    padding: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      height: 300,
      marginBottom: '0.7rem',
    },
  },
}));

const Home = ({ dataService }) => {
  const history = useHistory();
  const classes = useStyles();

  const auth = useAuth();
  const user = auth.user;
  const { id, role, affiliates } = user;

  const pathname = history.location.pathname;
  const sessionName = id + pathname;

  const menuInfo = useMenuInfo();
  useEffect(() => {
    const menuList = menuInfo?.menuInfo;
    if (!menuList) {
      menuInfo.fetchMenuInfo(id, role);
    }
  }, []);

  const [date, setDate] = useState(
    getSessionData(`${sessionName}P`).date || format(new Date(), 'yyyy-MM-dd')
  );
  const [params, setParams] = useState();

  const [initData, setInitData] = useState();
  const [cards, setCards] = useState(getSessionData(`${sessionName}C`));
  /* const [listCards, setListCards] = useState(
    getSessionData(`${sessionName}LC`)
  ); */

  const [initStoreData, setInitStoreData] = useState();
  const [barLoading, setBarLoading] = useState(false);
  const [barData, setBarData] = useState(getSessionData(`${sessionName}B`));
  const [storeType, setStoreType] = useState('amount');

  const [initMdData, setInitMdData] = useState();
  const [mdBarLoading, setMdBarLoading] = useState(false);
  const [mdBarData, setMdBarData] = useState(
    getSessionData(`${sessionName}MB`)
  );
  const [mdType, setMdType] = useState('count');

  const [nextBtn, setNextBtn] = useState(false);

  useEffect(() => {
    const prevDate = getSessionData(`${sessionName}P`);
    if (prevDate) {
      return;
    }
    setParams({
      date,
      role: user.role,
    });
  }, []);

  useEffect(() => {
    const prevDate = getSessionData(`${sessionName}P`).date;
    const newDate = format(new Date(date), 'yyyy-MM-dd');

    if (prevDate === newDate) {
      return;
    }
    setParams({
      date: format(new Date(date), 'yyyy-MM-dd'),
      role: user.role,
    });
  }, [date, user, sessionName]);

  useEffect(() => {
    const thisDate = getDate(date);
    const today = format(new Date(), 'yyyy-MM-dd');
    const disabled = thisDate === today ? true : false;
    setNextBtn(disabled);
  }, [date]);

  useEffect(() => {
    params && onSearch(params);
    params && onMdSearch(params);
    params && user.role !== 'common' && onStoreSearch(params);
  }, [params]);

  const makeRow = (arr) => {
    return arr.map((row) => {
      let newRow = {
        date: row[0],
        count: row[1],
        amount: row[2] * 1,
        perPrice: row[3] * 1,
        store: getStoreName(row[4], affiliates),
      };
      return newRow;
    });
  };

  const makeMdRow = (arr) => {
    return arr.map((row) => {
      let newMenu = {
        date: row[0],
        menu: row[1],
        count: row[2],
        amount: row[3] * 1,
      };
      return newMenu;
    });
  };

  const makeData = (init, type) => {
    if (!init) {
      return;
    }
    const { result, subResult } = init;
    return type === 'store'
      ? { result: makeRow(result), subResult: makeRow(subResult) }
      : { result: makeMdRow(result), subResult: makeMdRow(subResult) };
  };

  const onSearch = useCallback((params) => {
    setSessionData(`${sessionName}P`, params);
    dataService //
      .getAllSales(params)
      .then((res) => {
        //console.log(res);
        if (res === '세션이 만료되었습니다.') return;
        setInitData(res);
      });
  });

  const onStoreSearch = useCallback((params) => {
    if (user.role === 'common') {
      return;
    }
    setBarLoading(true);
    dataService //
      .getTopStores(params)
      .then((result) => {
        let { amount, count } = result || [];
        setInitStoreData({
          amount: makeData(amount, 'store'),
          count: makeData(count, 'store'),
        });
      });
  });

  const onMdSearch = useCallback((params) => {
    if (id !== 'unospay_mot' && id.startsWith('unospay')) {
      return;
    }

    setMdBarLoading(true);
    dataService //
      .getTopMenus(params)
      .then((result) => {
        //console.log(result);
        let { amount, count } = result || [];
        setInitMdData({
          amount: makeData(amount, 'md'),
          count: makeData(count, 'md'),
        });
      });
  });

  const getSalesData = (initData) => {
    //console.log(initData);
    return initData.reduce((prev, curr) => {
      const date = curr[0];

      if (!prev[date]) {
        prev = { ...prev, [date]: { count: 0, amount: 0, noTax: 0 } };
      }

      const count = prev[date].count + curr[1];
      const amount = prev[date].amount + curr[2] * 1;
      const noTax = prev[date].noTax + curr[3] * 1;

      const perPrice = count === 0 ? 0 : Math.floor(amount / count);
      const tax = Math.floor(((amount - noTax) / 1.1) * 0.1) || 0;
      const netSales = amount - tax;

      prev[date] = { date, count, amount, noTax, perPrice, tax, netSales };
      return prev;
    }, {});
  };

  /* const getPaytypeData = (initData) => {
    return initData.reduce((prev, curr) => {
      const date = curr[0];
      const count = curr[1];
      const amount = curr[2] * 1;
      const paytype = payType.find((type) => type.code === curr[4]).name;

      if (!prev[date]) {
        prev = { ...prev, [date]: [] };
      }

      prev[date].push({ count, amount, paytype });
      return prev;
    }, {});
  }; */

  useEffect(() => {
    const badge = nextBtn ? 'Today' : 'Day';

    let sumSalesContents = 0,
      netSalesContents = 0,
      countContents = 0,
      perPriceContents = 0;

    let sumSalesSubCont,
      netSalesSubCont,
      countSubCont,
      perPriceSubCont = ' ';

    if (initData && initData.length !== 0) {
      const salesData = Object.values(getSalesData(initData));

      const day = getFormatedDay(date);
      const subDay = getSubDay(day, 1);

      const defaultSales = {
        count: 0,
        amount: 0,
        perPrice: 0,
        tax: 0,
        netSales: 0,
      };

      const daySales = salesData.find((data) => data.date === day) || {
        date: day,
        ...defaultSales,
      };
      const subDaySales = salesData.find((data) => data.date === subDay) || {
        date: subDay,
        ...defaultSales,
      };

      //progress
      const amountPercent = getPercent(subDaySales.amount, daySales.amount);
      const netSalesPercent = getPercent(
        subDaySales.netSales,
        daySales.netSales
      );
      const countPercent = getPercent(subDaySales.count, daySales.count);
      const perPricePercent = getPercent(
        subDaySales.perPrice,
        daySales.perPrice
      );

      sumSalesContents = toCurrency(daySales.amount);
      netSalesContents = toCurrency(daySales.netSales);
      countContents = toCurrency(daySales.count);
      perPriceContents = toCurrency(daySales.perPrice);

      const makeSubContents = (percent, amount) => {
        return `전일대비 ${percent}% (${toCurrency(amount)})`;
      };

      sumSalesSubCont = makeSubContents(amountPercent, subDaySales.amount);
      netSalesSubCont = makeSubContents(netSalesPercent, subDaySales.netSales);
      countSubCont = makeSubContents(countPercent, subDaySales.count);
      perPriceSubCont = makeSubContents(perPricePercent, subDaySales.perPrice);

      /* paytype cards */
      /* if (user.role === 'common') {
        const payTypeData = getPaytypeData(initData);

        const paytypeCardData = payTypeData[day].map((sales) => {
          const { paytype, count, amount } = sales;
          return {
            title: paytype,
            contents: `${toCurrency(amount)} 원`,
            subContents: `${toCurrency(count)} 건`,
          };
        });

        setListCards(paytypeCardData);
        setSessionData(`${sessionName}LC`, paytypeCardData);
      } */
    }

    let cardData = [
      {
        title: '총매출',
        contents: sumSalesContents,
        subContents: sumSalesSubCont,
        badge,
      },
      {
        title: '순매출',
        contents: netSalesContents,
        subContents: netSalesSubCont,
        badge,
      },
      {
        title: '영수건수',
        contents: countContents,
        subContents: countSubCont,
        badge,
      },
      {
        title: '영수단가',
        contents: perPriceContents,
        subContents: perPriceSubCont,
        badge,
      },
    ];

    const storedCardData = getSessionData(`${sessionName}C`);
    if (!initData && storedCardData) {
      cardData = storedCardData;
    }

    setCards(cardData);
    setSessionData(`${sessionName}C`, cardData);
  }, [initData]);

  useEffect(() => {
    if (!initStoreData) {
      return;
    }

    const { amount, count } = initStoreData;
    const amountBarData = amount && makeBarData(amount, 'amount', 'store');
    const countBarData = count && makeBarData(count, 'count', 'store');
    const barData = {
      amount: amountBarData,
      count: countBarData,
    };

    setBarData(barData);
    setBarLoading(false);
    setSessionData(`${sessionName}B`, barData);
  }, [initStoreData]);

  useEffect(() => {
    if (!initMdData) {
      return;
    }

    const { amount, count } = initMdData;
    const amountBarData = amount && makeBarData(amount, 'amount', 'menu');
    const countBarData = count && makeBarData(count, 'count', 'menu');
    const barData = {
      amount: amountBarData,
      count: countBarData,
    };

    setMdBarData(barData);
    setMdBarLoading(false);
    setSessionData(`${sessionName}MB`, barData);
  }, [initMdData]);

  const handlePrev = () => {
    const subDay = subDays(new Date(date), 1);
    setDate(subDay);
  };

  const handleNext = () => {
    const nextDay = addDays(new Date(date), 1);
    setDate(nextDay);
  };

  const onTodayBtn = () => {
    setDate(new Date());
  };

  return (
    <>
      <Grid item className={classes.searchBox} xs={12}>
        <div className={classes.emptyBox}></div>
        <div className={classes.datepickerWrapper}>
          <Button className={classes.arrowBtn} onClick={handlePrev}>
            <ChevronLeftIcon className={classes.arrowIcon} />
          </Button>
          <div className={classes.datepicker}>
            <DatePickerOne
              value={date}
              setValue={setDate}
              type='day'
              disableFuture
              isClearIcon
            />
          </div>
          <Button
            className={classes.arrowBtn}
            disabled={nextBtn}
            onClick={handleNext}
          >
            <ChevronRightIcon className={classes.arrowIcon} />
          </Button>
        </div>
        <Button className={classes.dayBtn} onClick={onTodayBtn}>
          Today
        </Button>
      </Grid>

      <Grid item xs={12} className={classes.main}>
        <div className={classes.cardWrapper}>
          <div className={classes.cards}>
            {cards && <Cards cards={cards} />}
          </div>
        </div>

        {user.role !== 'common' && (
          <Grid component={Paper} className={classes.chart}>
            <div className={styles.infoWrapper}>
              <h4 className={styles.infoTitle}>
                매장 TOP 10
                <span>
                  {' '}
                  ({getFormatedDay(date)},{' '}
                  {storeType === 'amount' ? '매출기준' : '건수기준'})
                </span>
              </h4>
              <Radiogroup
                value={storeType}
                setValue={setStoreType}
                groupRef={radioGroupRef}
              />
            </div>
            {barLoading ? (
              <div className={styles.emptyBox}>
                <LoadingSpinner spinner='circle' color='#565656' />
              </div>
            ) : barData?.amount?.length > 0 ? (
              <CustomMixBarchart
                chartData={
                  storeType === 'amount' ? barData.amount : barData.count
                }
              />
            ) : (
              <div className={styles.emptyBox}>데이터가 없습니다.</div>
            )}
          </Grid>
        )}

        <Grid component={Paper} className={classes.chart}>
          <div className={styles.infoWrapper}>
            <h4 className={styles.infoTitle}>
              메뉴 TOP 10
              <span>
                {' '}
                ({getFormatedDay(date)},{' '}
                {mdType === 'amount' ? '옵션제외 매출기준' : '건수기준'})
              </span>
            </h4>
            <Radiogroup
              value={mdType}
              setValue={setMdType}
              groupRef={radioGroupRef}
            />
          </div>
          {mdBarLoading ? (
            <div className={styles.emptyBox}>
              <LoadingSpinner spinner='circle' color='#565656' />
            </div>
          ) : mdBarData?.count?.length > 0 ? (
            <CustomMixBarchart
              chartData={
                mdType === 'count' ? mdBarData.count : mdBarData.amount
              }
            />
          ) : (
            <div className={styles.emptyBox}>데이터가 없습니다.</div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Home;

const getDate = (date) => getFormatedDay(date);

const getPercent = (deno, numer) => {
  return deno === 0 || numer === 0
    ? 0
    : (((numer * 1) / (deno * 1)) * 100).toFixed(2);
};

function getStoreName(id, affiliateInfo) {
  return (
    affiliateInfo.find((affiliate) => affiliate.value === id)?.label || '-매장'
  );
}

const makeBarData = (obj, type, nameType) => {
  const { result, subResult } = obj;
  const barData = obj
    ? result.length !== 0
      ? [...result]
          .sort((a, b) => b[type] - a[type])
          .map((v) => {
            const { date } = v;
            const name = v[nameType];
            const value = v[type];
            const subData = subResult.find((sub) => sub[nameType] === name);
            const subDate = format(subDays(new Date(date), 1), 'yyyy-MM-dd');

            return {
              name,
              [date]: value * 1,
              [subDate]: (subData && subData[type]) || 0,
            };
          })
      : [...subResult]
          .sort((a, b) => b[type] - a[type])
          .map((v) => {
            const name = v[nameType];
            const date = getFormatedDay(addDays(new Date(v.date), 1));
            return {
              name,
              [date]: 0,
              [v.date]: v[type],
            };
          })
    : [];

  return barData;
};
